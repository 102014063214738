import React from 'react'
import {LIVE_BASE_URL} from '../../constants';
import dynamic from "next/dynamic";
import MenuSlider from "../slider/MenuSlider";

const WebSocketNoSSR = dynamic(import('react-websocket'), {
    ssr: false
})

const marketsStatusWSUrl = LIVE_BASE_URL + 'status';
// const marketsStatusWSUrl = new W3CWebSocket('ws://10.8.0.1:5837/status');

let marketsSummaryWSUrl = LIVE_BASE_URL + 'indices';
// let marketsSummaryWSUrl = new W3CWebSocket('ws://10.8.0.1:5837/indices');

export default function MarketSummaryMenu() {

    return (
        <section className='container marketMenu'>
            <MenuSlider/>

            {/*<WebSocketNoSSR url={marketsStatusWSUrl}*/}
            {/*                onMessage={marketsStatusLiveHandler}/>*/}

            {/*<WebSocketNoSSR url={marketsSummaryWSUrl}*/}
            {/*                onMessage={marketsSummaryLiveHandler}/>*/}
        </section>
    )
}