import React from "react";

const BoxHtmlLoader = props => (
    <div className='col position-absolute m-0 p-0'>
        <div className={`${props.className} d-flex justify-content-center align-items-center text-secondary`}
             style={{backgroundColor: '#f0f0f0', width: props.width, height: props.height}}>
            <div className='text-center rounded font-bold'
                 style={{backgroundColor: '#d5d0cd', color: '#f2f2f2', width: '50px'}}>
                بهابین
            </div>
        </div>
    </div>
)

export default BoxHtmlLoader