import React from "react";
import Link from 'next/link'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInstagram} from "@fortawesome/free-brands-svg-icons";

export default function Footer() {
    const year = new Date().getFullYear()
    return (
        <>
            <footer className="text-center text-lg-start text-muted mt-4 border-top" style={{backgroundColor: '#EEEEEE !important'}}>
                <div
                    className="d-flex justify-content-center justify-content-lg-between p-4"
                >
                    <div className="me-5 d-none d-lg-flex align-items-center">
                        <span className="mr-2">از طریق شبکه های اجتماعی با ما در ارتباط باشید:</span>
                    </div>

                    <div>
                        <a href="https://www.instagram.com/bahabin.official" className="me-4 text-reset">
                            <FontAwesomeIcon icon={faInstagram} />
                        </a>
                    </div>
                </div>
                <section className="">
                    <div className="container text-center text-md-start mt-5">
                        <div className="row mt-3 text-right">
                            <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                                <p>
                                    یکی پس از دیگری همه نیازمندیهای مالی رو در بهابین خواهید یافت...
                                </p>
                            </div>
                            <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                                <strong className="text-uppercase fw-bold mb-4">
                                    محصولات
                                </strong>
                                <p className="footer-link">
                                    <Link href="/" className="text-reset">ناشر قیمت</Link>
                                </p>
                                <p className="footer-link">
                                    <Link href="/payment/sim-card-recharge" className="text-reset">شارژ سیم کارت</Link>
                                </p>
                                <p className="footer-link">
                                    <Link href="/payment/internet-packages" className="text-reset">بسته اینترنت
                                        همراه</Link>
                                </p>
                            </div>
                            <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                                <strong className="text-uppercase fw-bold mb-4">
                                    شرکت
                                </strong>
                                <p className="footer-link">
                                    <Link href="/about" className="text-reset">درباره</Link>
                                </p>
                                <p className="footer-link">
                                    <Link href="/download" className="text-reset">دانلود</Link>
                                </p>
                                <p className="footer-link">
                                    <Link href="/support" className="text-reset">پشتیبانی</Link>
                                </p>
                            </div>
                            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                                <strong className="text-uppercase fw-bold mb-4">
                                    تماس با ما
                                </strong>
                                <p><FontAwesomeIcon icon='mail-bulk' size='lg' className='pl-2'/>تهران - شهریار، شهرک
                                    وائین، خیابان مادر، کوچه مینا7 (جالیز)، پلاک: 89</p>
                                <p><FontAwesomeIcon icon='phone' size='lg' className='pl-2'/><a className='baha-link'  href="tel:+982191018762">021-91018762</a></p>
                                <p><FontAwesomeIcon icon='mail-bulk' size='lg' className='pl-2'/><a className='baha-link' href="mailto:business@bahabin.com">business[at]bahabin.com</a></p>
                            </div>
                        </div>
                    </div>
                </section>
                <div className='container'>
                    <div className=' py-3 border-top'>
                        <div className='row dl-box'>
                            <a className="col-md-2 py-2"
                               href="https://play.google.com/store/apps/details?id=com.bahabin.app">
                                <div className="row">
                                    <div className="col-4 pr-5 text-dark"><FontAwesomeIcon icon='play' size='2x'/></div>
                                    <div className="col-8 dl-text"><span>دانلود از</span><br/><strong>فروشگاه
                                        گوگل</strong></div>
                                </div>
                            </a>
                            <a className="col-md-2 py-2" href="https://cafebazaar.ir/app/com.bahabin.app">
                                <div className="row">
                                    <div className="col-4 pr-5 text-dark"><FontAwesomeIcon icon='shopping-basket'
                                                                                           size='2x'/></div>
                                    <div className="col-8 dl-text"><span>دانلود از</span><br/><strong>کافه
                                        بازار</strong></div>
                                </div>
                            </a>
                            <a className="col-md-2 py-2" href="https://myket.ir/app/com.bahabin.app">
                                <div className="row">
                                    <div className="col-4 pr-5 text-dark"><b style={{fontSize: '30px', lineHeight: '1.1'}} className=''>M</b></div>
                                    <div className="col-8 dl-text"><span>دانلود از</span><br /><strong>مایکت </strong></div>
                                </div>
                            </a>
                        </div>
                        <div className="text-center footer-link">{year} © Copyright:
                            <Link className="pl-1 text-reset fw-bold" href="/">www.BahaBin.com</Link>
                        </div>
                    </div>
                </div>

            </footer>
        </>
    );
}