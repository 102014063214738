import React, {useEffect, useState} from 'react';
import styles from '../../styles/StockRectangle.module.css';
import clsx from 'clsx';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useIndicesSliderItems} from "../store/IndicesSliderItems";
import {getNumberSign} from "../../util/APIUtils";
import {toFixedAndLocale} from "../../util/DominantUtil";
import Skeleton  from 'react-loading-skeleton';

const StockRectangleItem = ({
                                arrowColor,
                                stockName,
                                stockPrice,
                                changePercentage,
                                changeAmount,
                                iconName,
                            }) => {
    return (
        <a href="#" className={clsx("text-decoration-none", styles["stock-link"])}>
            <div className={clsx("d-flex align-items-center", styles["stock-rectangle"])}>
                <div className={clsx("text-left", styles["change-info"])}>
                    <div
                        className={clsx(
                            styles["change-percentage"],
                            styles[arrowColor + "-color"]
                        )}
                    >
                        {changePercentage}
                    </div>
                    <div
                        className={clsx(
                            styles["change-amount"],
                            styles[arrowColor + "-color"]
                        )}
                    >
                        {changeAmount}
                    </div>
                </div>
                <div className={clsx("ms-auto", styles["stock-info"])}>
                    <div className={clsx('text-right', 'persian-font', styles["stock-name"])}>{stockName}</div>
                    <div className={clsx(styles["stock-price"])}>{stockPrice}</div>
                </div>
                <div className={clsx(styles.arrow, styles["arrow-" + arrowColor])}>
                    <FontAwesomeIcon icon={iconName}
                                     className={clsx(styles[arrowColor + "-color"], styles["arrow-" + arrowColor])}/>
                </div>
                <div className={clsx(styles["change-info-mobile"])}>
                    <div className={clsx(styles["arrow-mobile"], styles["arrow-" + arrowColor])}>
                        <FontAwesomeIcon icon={iconName} className={clsx(styles[arrowColor + "-color"])}/>
                        <span className="ms-1">{changePercentage}</span>
                    </div>
                </div>
            </div>
        </a>
    );
};

export const getArrowIconProperties = (changeAmount) => {
    let arrowColor = "grey";
    let iconName = "left";

    if (changeAmount > 0) {
        arrowColor = "green";
        iconName = "up";
    } else if (changeAmount < 0) {
        arrowColor = "red";
        iconName = "down";
    }

    return {arrowColor, iconName};
};

const StockRectangleItemLoader = () => {
    return (
        <div className={clsx("d-flex align-items-center", styles["stock-rectangle"])}>
            <div className={clsx("text-left", styles["change-info"])}>
                <div className={clsx(styles["change-percentage"])}><Skeleton width={50} /></div>
                <div className={clsx(styles["change-amount"])}><Skeleton width={50} /></div>
            </div>
            <div className={clsx("ms-auto", styles["stock-info"])}>
                <div className={clsx('text-right', 'persian-font', styles["stock-name"])}><Skeleton width={100} /></div>
                <div className={clsx(styles["stock-price"])}><Skeleton width={80} /></div>
            </div>
            <div className={clsx(styles.arrow, styles["arrow-grey"])}><Skeleton circle={true} width={16} height={16} /></div>
            <div className={clsx(styles["change-info-mobile"])}>
                <div className={clsx(styles["arrow-mobile"], styles["arrow-grey"])}><Skeleton circle={true} width={14} height={14} /><span className="ms-1"><Skeleton width={40} /></span></div>
            </div>
        </div>
    );
};

const StockRectangle = () => {
    const [indicesSliderItems, refreshIndicesList] = useIndicesSliderItems(state => [state.indicesSliderItems, state.refreshIndicesList]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        refreshIndicesList()

        if (indicesSliderItems) {
            setLoading(false);
        }
    }, [indicesSliderItems]);

    return (
        <div className="container text-center mt-3 mb-2">
            <div className="row ltr">
                <div className="col d-flex justify-content-center align-items-center">
                    <div className={clsx(styles['rectangles-container'])}>
                        {loading ? (
                            Array(5).fill().map((_, index) => <StockRectangleItemLoader key={index} />)
                        ) : (
                            indicesSliderItems.map((stock, index) => {
                                const {arrowColor, iconName} = getArrowIconProperties(stock.changePercent);
                                return (
                                    <StockRectangleItem
                                        key={index}
                                        arrowColor={arrowColor}
                                        stockName={stock.companyName}
                                        stockPrice={toFixedAndLocale(stock.last)}
                                        changePercentage={getNumberSign(stock.changePercent) + stock.changePercent + '%'}
                                        changeAmount={getNumberSign(stock.change) + stock.change}
                                        iconName={"arrow-" + iconName}
                                    />
                                );
                            })
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StockRectangle;
