import {ACCESS_TOKEN, API_BASE_URL} from '../constants';
import {useAuthenticated} from "../components/store/Autehnticated";
import axios from "../components/plugins/axios";
import {parseIranMarketApiResponse} from "./DominantUtil";


const request = (options) => {
    const headers = new Headers({
        'Content-Type': 'application/json',
    })

    if (options.token) {
        headers.append('Authorization', 'Bearer ' + options.token)
    }

    const defaults = {headers: headers};
    options = Object.assign({}, defaults, options);

    return fetch(options.url, options)
        .then(response =>
            response.json().then(json => {
                if (!response.ok) {
                    return Promise.reject(json);
                }
                return json;
            })
        );
};

export function getCurrentUser(token) {
    if (!token) {
        return Promise.reject("No access token set.");
    }

    return request({
        url: API_BASE_URL + "/user/me",
        method: 'GET',
        token: token
    });
}

export function login(loginRequest) {
    return request({
        url: API_BASE_URL + "/auth/login",
        method: 'POST',
        body: JSON.stringify(loginRequest)
    });
}

export function signup(signupRequest) {
    return request({
        url: API_BASE_URL + "/auth/signup",
        method: 'POST',
        body: JSON.stringify(signupRequest)
    });
}

export function getNumberSign(num) {
    return num.toString().includes("-") ? '' : num > 0 ? '+' : num < 0 ? '-' : '';
}

export async function callIranMarketStockSummaryAPI(url) {
    const response = await axios.get(url);
    const res = parseIranMarketApiResponse(response.data);
    return res;
}

export async function fetchStockSummary(symbol, parser) {
    const url = `https://api.bahabin.com/quotes?symbols=${symbol}`;
    const response = await fetch(url);
    return await response.json();
}