import create from 'zustand'
import axios from "../plugins/axios";
const addr = 'https://api.bahabin.com/quotes/indices';

export const useIndicesSliderItems = create(
    (set, get) => ({
        indicesSliderItems: null,
        setIndicesSliderItems: (indicesSliderItems) => set({indicesSliderItems: indicesSliderItems}),
        refreshIndicesList: () => get().indicesSliderItems === null && callIndicesApi(),
    }))

const callIndicesApi = () => {
    axios
        .get(addr).then((res) => {
        useIndicesSliderItems.getState().setIndicesSliderItems(res.data);
        }
    ).catch((error) => {
    });
}