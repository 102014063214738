import React from 'react'
import {Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import './header.module.scss'
import {useRouter} from "next/router";
import ActiveLink from "./ActiveLink";
import Link from 'next/link'
import NavBarAutoSuggest from "../dropdown/NavBarAutoSuggest";
import {useWindowSize} from "../../util/DominantUtil";

export default function Header() {
    const router = useRouter();
    const {width} = useWindowSize()
    // const [navBarIsOpen, setNavBarIsOpen] = useState(false);
    // const {authenticated, user, deleteEverything} = useAuthenticated(state => ({
    //     authenticated: state.authenticated,
    //     user: state.user,
    //     deleteEverything: state.deleteEverything
    // }), shallow)

    function getItem(pathname, label, isActive = false) {
        return (
            <Link href={pathname} passHref>
                <Nav.Link className={`${isActive ? "active" : ""} text-center`}>
                    {pathname === "/"
                        ? <span className="text-nowrap">{label}</span>
                        : label
                    }
                </Nav.Link>
            </Link>
        );
    }


    const logo = getLogo()

    return (
        <header className="ltr w-100 fixed-header">
            <Navbar expand="md" bg="light">
                <Container>
                    <div className="d-flex flex-column flex-md-row w-100">
                        <div className="d-none d-md-block col-md-1">
                            {logo}
                        </div>
                        <div className="d-block d-md-none col-12 text-center mb-2">
                            {logo}
                        </div>

                        <div className="col-12 col-md-6 mb-2 mb-md-0 rtl">
                            <NavBarAutoSuggest
                                closeDropDown={true}
                                onChangeHandler={(data) => {
                                    router.push(`/${getMarketUrl(data.type)}/${data.ticker}`);
                                }}
                                className="w-100"
                            />
                        </div>
                        <div className="col-12 col-md-5 d-flex rtl flex-wrap align-items-center">
                            {getItem("/", "بهابین", true)}
                            <NavDropdown title="بازار" className='text-center'>
                                {getItem("/currencies/watchlist", "ارز")}
                                {getItem("/cryptocurrencies/watchlist", "ارز ‌دیجیتال")}
                                {getItem("/gold/watchlist", "طلا")}
                                <NavDropdown title="بورس" className='text-center'>
                                    <NavDropdown.Item>{getItem("/financial-market/market", "بازار")}</NavDropdown.Item>
                                    <NavDropdown.Item>{getItem("/financial-market/watchlist", "دیده‌بان")}</NavDropdown.Item>
                                    <NavDropdown.Item>{getItem("/financial-market/signal", "سیگنال")}</NavDropdown.Item>
                                    <NavDropdown.Item>{getItem("/financial-market/marketView", "نقشه‌بازدهی")}</NavDropdown.Item>
                                    <NavDropdown.Item>{getItem("/financial-market/buyers-sellers", "تحرکات")}</NavDropdown.Item>
                                    <NavDropdown.Item>{getItem("/financial-market/trends", "محبوب")}</NavDropdown.Item>
                                </NavDropdown>
                                <NavDropdown title="بازار جهانی" className='text-center'>
                                    <NavDropdown.Item>{getItem("/forex/watchlist", "بازار فارکس")}</NavDropdown.Item>
                                    <NavDropdown.Item>{getItem("/energy/watchlist", "بازار انرژی")}</NavDropdown.Item>
                                    <NavDropdown.Item>{getItem("/metals/watchlist", "بازار فلزات")}</NavDropdown.Item>
                                    <NavDropdown.Item>{getItem("/agriculture/watchlist", "بازار کشاورزی")}</NavDropdown.Item>
                                </NavDropdown>
                            </NavDropdown>
                            <NavDropdown title="خبر" className='text-center'>
                                <NavDropdown.Item>{getItem("/news?market=طلا و ارز", "طلا و ارز")}</NavDropdown.Item>
                                <NavDropdown.Item>{getItem("/news?market=ارز دیجیتال", "ارز دیجیتال")}</NavDropdown.Item>
                                <NavDropdown.Item>{getItem("/news?market=بورس", "بورس")}</NavDropdown.Item>
                                <NavDropdown.Item>{getItem("/news?market=خودرو", "خودرو")}</NavDropdown.Item>
                                <NavDropdown.Item>{getItem("/news?market=بانک", "بانک")}</NavDropdown.Item>
                            </NavDropdown>
                            {getItem("/live-tv", "پخش زنده")}
                            {/*<NavDropdown title="فروشگاه" className='text-center'>*/}
                            {/*    <NavDropdown.Item>{getItem("/payment/sim-card-recharge", "شارژ")}</NavDropdown.Item>*/}
                            {/*    <NavDropdown.Item>{getItem("/payment/internet-packages", "بسته اینترنت")}</NavDropdown.Item>*/}
                            {/*</NavDropdown>*/}
                            {getItem("/download", "دانلود")}
                            <NavDropdown title="درباره" className='text-center'>
                                <NavDropdown.Item>{getItem("/support", "پشتیبانی")}</NavDropdown.Item>
                                <NavDropdown.Item>{getItem("/partnership", "شراکت")}</NavDropdown.Item>
                                <NavDropdown.Item>{getItem("/privacy-and-terms", "حریم خصوصی کاربران")}</NavDropdown.Item>
                                <NavDropdown.Item>{getItem("/about", "درباره بها")}</NavDropdown.Item>
                            </NavDropdown>
                        </div>
                    </div>
                </Container>
            </Navbar>
        </header>

    )
}

function getMarketUrl(s) {
    if (s === 'IRFM') return 'financial-market'
    if (s === 'IRCUR') return 'currencies'
    if (s === 'IRMET') return 'gold'
    if (s === 'CRYP') return 'cryptocurrencies'
    return s;
}

function getLogo() {
    return (
        <h1>
            <Link href="/">
                <a>
                    <img
                        alt="بهابین - منبع قیمت لحظه ای بازار آزاد و اخبار مالی"
                        src="/images/logo/bahabin-logo-v3-1.png"
                        style={{ width: '28px', height: '30px' }}
                        width="28"
                        height="30"
                        className="d-inline-block my-1"
                    />
                </a>
            </Link>
        </h1>
    );
}
