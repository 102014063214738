import React, {useEffect, useState} from "react";
import moment from "jalali-moment";

// import Joyride from "react-joyride";

function getAbs(num) {
    return Math.abs(Number(num));
}

export function convertNumber(num, decimalPlaces = 3) {
    const abs = getAbs(num);
    return toFixedAndLocale(formatNumber(abs), decimalPlaces) + findScale(abs);
}

export function getNumWithScale(num) {
    return toFixedAndLocale(formatNumber(num), 3) + findScale(num)
}

export function convertNumberWithoutSuffix(num, decimalPlaces = 3) {
    const abs = getAbs(num);
    return toFixedAndLocale(formatNumber(abs), decimalPlaces);
}

export function toFixedAndLocale(num, decimalPlaces) {
    if (num === undefined) return '';
    return num.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: decimalPlaces})
}

export function toFixed(num, decimalPlaces) {
    return Number(num).toFixed(decimalPlaces)
}

export function numWithComma(num) {
    return Number(num).toLocaleString()
}

export function to3SeparatedComma(num) {
    const nf = new Intl.NumberFormat();
    return nf.format(num);
}

export function to3SeparatedCommaWithoutDecimal(num) {
    const nf = new Intl.NumberFormat();
    return nf.format(num);
}

export function getSign(v) {
    if (v === undefined) return '';
    let stringNum = v.toString();
    if (stringNum.includes('-')) return '-'
    else if (stringNum.includes('+')) return '+'
    return v > 0 ? '+' : v === 0 ? '' : '-';
}

export function getSignWithPercent(num) {
    return getSign(num) + convertNumberWithoutSuffix(num, 2)
}

export function getAverage(arr) {
    let sum = 0;
    for (let i = 0; i < arr.length; i++) sum += parseFloat(arr[i], 10);

    return sum / arr.length;
}

export function changePercentage(a, b) {
    const cp = (b - a) / a * 100;
    const ChangePercent = (cp > 0 ? getSign(cp) : '') + toFixed(cp, 2);
    return ChangePercent + '%'
}

export function findScale(num) {
    return num >= 1.0e+9
        ? "B"
        : num >= 1.0e+6
            ? "M"
            : num >= 1.0e+3
                ? "K"
                : "";
}

export function formatNumber(num) {
    return num >= 1.0e+9
        ? num / 1.0e+9
        : num >= 1.0e+6
            ? num / 1.0e+6
            : num >= 1.0e+3
                ? num / 1.0e+3
                : num;
}

export function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
        scaledHeight: undefined
    });

    useEffect(() => {
        // only execute all the code below in client side
        if (typeof window !== 'undefined') {
            // Handler to call on window resize
            function handleResize() {
                // Set window width/height to state
                const height = window.innerHeight;
                setWindowSize({
                    width: window.innerWidth,
                    height: height,
                    scaledHeight: height > 400 ?
                        height - 243 :
                        height
                });
            }

            // Add event listener
            window.addEventListener("resize", handleResize);

            // Call handler right away so state gets updated with initial window size
            handleResize();

            // Remove event listener on cleanup
            return () => window.removeEventListener("resize", handleResize);
        }
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
}

export function getBahabinText() {
    return ' | بهابین';
}

export function getTodayString() {
    return 'امروز';
}

export function getTodayJalaliDate() {
    return toPersianNumber(moment(new Date()).locale('fa').format("dddd DD MMMM"));
}

export function getTitleFaIranFinancialMarkets(page) {
    return page + ' بورس';
}

function getPageTitle(market, page) {
    const SEPARATOR = ' | ';
    if (page) {
        return page + SEPARATOR
    }
    return market + SEPARATOR
}

export function getTitleFaCurrencies(page) {
    return getPageTitle('- ارزها', page);
}

// export function getJoyRide(tooltipSteps) {
//
//     return isMobile ? <></> : <Joyride
//         styles={{
//             options: {
//                 // arrowColor: '#e3ffeb',
//                 primaryColor: '#34495e',
//                 zIndex: 99999,
//             }
//         }}
//         run={!!tooltipSteps}
//         steps={tooltipSteps}
//         locale={{
//             back: 'بازگشت', close: 'بستن', last: 'اتمام', next: 'بعدی', skip: 'انصراف', open: 'نمایش راهنما...'
//         }}
//         continuous={true}
//         showProgress={true}
//         showSkipButton={true}
//     />
// }

export function lastChangeRenderer(v) {
    return getSignWithPercent(v, 2)
}

export function bigNumberFormat(v) {
    return v ? convertNumber(v, 0) : '';
}

export function getColor(n) {
    return n > 0 ? 'green-number' : n < 0 ? 'red-number' : ''
}

export function toPersianNumber(n) {
    const digits = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'];

    return n
        .toString()
        .replace(/\d/g, x => digits[x]);
}

export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function tryCatch(fn) {
    try {
        return fn();
    } catch (err) {
        console.log(err)
    }
}

export function compareForSort(a, b) {
    if (a < b) {
        return -1;
    }
    if (a > b) {
        return 1;
    }
    return 0;
}

function getDoubleOrZero(stringNum) {
    const floatNum = parseFloat(stringNum);
    return isNaN(floatNum) ? 0 : floatNum
}

export function convertStockFieldsToNumbers(item) {
    item['c'] = getDoubleOrZero(item['c']);
    item['p'] = getDoubleOrZero(item['p']);
    item['P'] = getDoubleOrZero(item['P']);
    item['o'] = getDoubleOrZero(item['o']);
    item['h'] = getDoubleOrZero(item['h']);
    item['l'] = getDoubleOrZero(item['l']);
    item['v'] = getDoubleOrZero(item['v']);
    item['w'] = getDoubleOrZero(item['w']);
    item['cs'] = getDoubleOrZero(item['cs']);
    item['ord'] = getDoubleOrZero(item['ord']);
    return item;
}

export const getSymbolFromFullSymbol = (s) => s ? (s.includes(":") ? s.split(":")[1] : s) : "";

export function addMetadataToForeignMarketResponse(data, symbolsGlobalCache) {
    const processItem = (item) => {
        let symbol = item.s.replace(item.bm + ":", "");
        item.faN = symbolsGlobalCache?.[item.s]?.faN ?? symbol;
        const normalizedItem = convertStockFieldsToNumbers(item);
        if (item.bm === "CRYP") normalizedItem.mc = normalizedItem.cs * normalizedItem.c;
        return normalizedItem;
    };

    if (Array.isArray(data)) {
        return data.map(processItem);
    } else {
        return processItem(data);
    }
}

export function parseIranMarketApiResponse(list) {
    return list
        .map(item => {
            item.s = item.s.replace(item.bm + ":","");
            return convertStockFieldsToNumbers(item);
        });
}

export function makeDataLastUpdateJson(data, lastUpdate) {
    return {
        'lastUpdate': lastUpdate,
        'data': data
    }
}