import axios from 'axios'
import {useAuthenticated} from "../store/Autehnticated";

const http = axios.create({
    // baseURL: 'https://api.bahabin.com',
    baseURL: 'https://api.bahabin.com',
    // baseURL: 'http://localhost:3100',

    headers: {
        'Content-Type': 'application/json',
    }
})

http.interceptors.response.use(config => {
        return config
    }, function (error) {
        if (error && error.response) {
            if (error.response.status === 401) {
                // localStorage.clear()
            }
        }
        return Promise.reject(error)
    }
)

export default http
