import {Helmet} from 'react-helmet'
import "tailwindcss/tailwind.css";
import 'bootstrap/dist/css/bootstrap.min.css'
import '../styles/globals.css'
import '../styles/font-awesome-animation.min.css'
import 'react-loading-skeleton/dist/skeleton.css';
import {config, library} from '@fortawesome/fontawesome-svg-core'
import {
    faAppleAlt,
    faArrowDown,
    faArrowLeft,
    faArrowUp,
    faAtlas,
    faChartLine,
    faCheckCircle,
    faCheckSquare,
    faClock,
    faCoins,
    faDollarSign,
    faDownload,
    faExclamationCircle,
    faGlobe,
    faInbox,
    faLock,
    faMailBulk,
    faMoneyBill,
    faMoon,
    faPhone,
    faPlay,
    faPoll,
    faReceipt,
    faShoppingBasket,
    faSimCard,
    faStreetView,
    faSun,
    faSync,
    faTable,
    faTh,
    faUser,
    faWifi
} from '@fortawesome/free-solid-svg-icons'
import {faInstagram} from "@fortawesome/free-brands-svg-icons";
import '@fortawesome/fontawesome-svg-core/styles.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, {Component, useEffect, useState} from "react";
import {useRouter} from "next/router";
import Footer from "../components/footer/footer";
import 'video.js/dist/video-js.css'
import MarketSummaryMenu from "../components/marketSummary/marketSummaryMenu";
import Header from "../components/header/header";
import useAdSense from "../components/ads/loaders";
import {isMobile} from "react-device-detect";
import StockRectangle from "../components/marketSummary/StockRectangle";
import {router} from "websocket";
import {DefaultSeo} from "next-seo";
import App from "next/app";

library.add(faUser, faLock, faMoon, faSun, faSync, faGlobe, faStreetView, faDollarSign, faPoll, faCheckSquare, faTable,
    faShoppingBasket, faPlay, faDownload, faAppleAlt, faAtlas, faMoneyBill, faCoins, faChartLine, faCheckCircle,
    faExclamationCircle, faSimCard, faWifi, faClock, faTh, faMailBulk, faInbox, faPhone, faReceipt, faInstagram, faArrowLeft,
    faArrowUp,
    faArrowDown)
config.autoAddCss = false // Tell Font Awesome to skip adding the CSS automatically since it's being imported above

// function makeYektanetScriptAddress() {
//     const now = new Date();
//     const yektaNetScript = 'https://cdn.yektanet.com/js/bahabin.com/native-bahabin.com-15077.js'
//     return yektaNetScript + '?v=' + now.getFullYear().toString() + '0' + now.getMonth() + '0' + now.getDate() + '0' + now.getHours();
// }

function MyApp({Component, pageProps, canonicalUrl}) {
    const [mobile, setMobile] = useState(false);
    const router = useRouter();

    useEffect(() => {
        // refreshCryptoIsAccessible();
        window.scrollTo(0, 0);
        setMobile(isMobile);
    }, [])

    function checkCurrentPageIsPaymentPage() {
        return !router.pathname.includes("payment")
    }

    return <div dir='rtl'>
        <Helmet
            htmlAttributes={{lang: 'fa'}}
            meta={[
                {
                    name: 'viewport',
                    content: 'width=device-width, initial-scale=1',
                },
                {
                    name: "charSet",
                    content: "UTF-8"
                },
                {
                    name: 'google-site-verification',
                    content: 'G9o534Q3eTW8g6Nz9APtvYfRGpWMOrGPPk8jfKP6n9s'
                },
                {property: 'og:url', content: canonicalUrl},
                {property: 'og:type', content: "website"},
                {property: 'og:image', content: 'https://www.bahabin.com/images/bahabin-big-logo.png'},
                {property: 'og:image:alt', content: 'بهابین'},
                {property: 'twitter:url', content: canonicalUrl},
                {property: 'twitter:image', content: 'https://www.bahabin.com/images/bahabin-big-logo.png'},
                {property: 'twitter:image:alt', content: 'بهابین'},
            ]}
        >
            <DefaultSeo canonical={canonicalUrl} />
            <link
                async
                href="https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500&display=swap"
                rel="stylesheet"
                as="style"
            />
            <link
                href="https://cdnjs.cloudflare.com/ajax/libs/vazir-font/27.2.2/font-face.min.css"
                rel="stylesheet"
                type="text/css"
                as="style"
            />
            {/*<script src={makeYektanetScriptAddress()}/>*/}
        </Helmet>
        <Header />
        <main>
            <StockRectangle/>
            <Component {...pageProps} />
        </main>
        <Footer/>
    </div>
}

MyApp.getInitialProps = async (appContext) => {
    const { ctx } = appContext;
    const { req } = ctx;

    let canonicalUrl = '';

    if (req) {
        const protocol = req.headers['x-forwarded-proto'] || 'http';
        const host = req.headers.host;
        const path = req.url;
        canonicalUrl = `${protocol}://${host}${path}`;
    }

    const appProps = await App.getInitialProps(appContext);

    return { ...appProps, canonicalUrl };
};

export default (MyApp);
