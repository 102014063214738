import create from 'zustand'
import {persist} from "zustand/middleware"
import {getCurrentUser} from "../../util/APIUtils";
import http from "../plugins/axios";

export const useAuthenticated = create(persist(
    (set, get) => ({
        authenticated: null,
        user: null,
        tkn: null,
        haveAuthenticated: (token) => {
            getCurrentUser(token).then((res) => {
                set({tkn: token, authenticated: true, user: res})
                setAxiosToken(token)
            })
        },
        deleteEverything: () => {
            set({ tkn: null, authenticated: false, user: null}, true)
            setAxiosToken(null)
        },
    }),
    {
        name: "auth-storage",
    }))

function setAxiosToken(token) {
    http.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}